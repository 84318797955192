export const brands = ["All", "Leonard", "Cook"];

export const getBrand = (itemNumber) => {
  // Example item number: B-BARN-1024-800-C
  if (!itemNumber || !itemNumber.startsWith("B-")) {
    return "All";
  }

  const brand = itemNumber.split("-")[4];
  if (brand === "C") {
    return "Cook";
  }

  if (brand === "L") {
    return "Leonard";
  }

  if (!brand) {
    return "All";
  }

  return brand;
};

export const getSize = (itemNumber) => {
  // Example item number: B-BARN-1024-800-C
  if (!itemNumber) {
    return "n/a";
  }
  try {
    if (itemNumber.startsWith("B-")) {
      const fullSize = itemNumber.split("-")[2];
      const rawWidth = fullSize.substring(0, 2);
      const width = rawWidth.startsWith("0") ? rawWidth.substring(1) : rawWidth;
      const rawLength = fullSize.substring(2, 4);
      const length = rawLength.startsWith("0")
        ? rawLength.substring(1)
        : rawLength;
      return `${width}'x${length}'`;
    }

    if (itemNumber.startsWith("TRL") && itemNumber.includes("-")) {
      const fullSize = itemNumber.split("-")[1];
      const rawWidth = fullSize.substring(0, 2);
      const rawLength = fullSize.substring(2, 4);
      const length = rawLength.startsWith("0")
        ? rawLength.substring(1)
        : rawLength;
      const width = rawWidth.startsWith("0")
        ? rawWidth.substring(1)
        : rawWidth.substring(0, 1) + "." + rawWidth.substring(1);

      return `${width}'x${length}'`;
    }
  } catch (error) {
    console.log("Error in getSize function:", itemNumber);
    console.log(error);
  }

  return "";
};

export const calculateMonthlyPayment = (
  listPrice,
  term = 60 | 48 | 36,
  isTrailer = false
) => {
  if (isTrailer) {
    // chad and I compared to Hometowne Capitals portal and this number seemed to be pretty close to the dollar amount
    return listPrice * 0.0475;
  }

  const rate = term === 60 ? 0.36 : term === 48 ? 0.475 : 0.55; // 60 months = 36% APR, 48 months = 47.5% APR, 36 months = 55% APR

  return getMonthlyPayment(rate, 60, listPrice);
};

/**
 *
 * @param {*} rate - annual interest rate
 * @param {*} nper - number of periods
 * @param {*} pv - present value
 * @param {*} fv - future value
 * @param {*} type - type of payment (0 = end of period, 1 = beginning of period)
 * @returns monthly payment
 */
function getMonthlyPayment(rate, nper, pv, fv = 0, type = 0) {
  // Convert annual interest rate to monthly rate
  const monthlyRate = rate / 12;

  // Handle edge case when rate is 0
  if (monthlyRate === 0) {
    return (-pv - fv) / nper;
  }

  // Calculate the present value interest factor
  const pvif = Math.pow(1 + monthlyRate, nper);

  // Calculate the payment using the PMT formula
  let pmt = (monthlyRate * pv * (pvif + fv)) / (pvif - 1);

  // Adjust for payments at the beginning of the period if type is 1
  if (type === 1) {
    pmt = pmt / (1 + monthlyRate);
  }

  // Return the rounded negative payment (Excel returns payments as negative)
  return Math.abs(Math.round(-pmt));
}

// Example usage:
// const payment = calculateMonthlyPayment(300000, 5.5, 360); // 30-year mortgage at 5.5% APY
// console.log(payment); // Returns monthly payment amount

export const determineTypeDisplayName = (itemName, floorPlanNumber) => {
  let newType = "";
  if (itemName.toUpperCase().includes("CONDO")) {
    newType = "Dog Kennel";
  } else if (itemName.toUpperCase().includes("HIRK")) {
    newType = "Saltbox";
  } else if (itemName.toUpperCase().includes("HMP2")) {
    newType = "Vinyl A-Frame";
  } else if (itemName.toUpperCase().includes("LNTO")) {
    newType = "Lean To";
  } else if (itemName.toUpperCase().includes("LNTM")) {
    newType = "Metal Framed Lean To";
  } else if (itemName.toUpperCase().includes("MBY1")) {
    newType = "Barn";
  } else if (itemName.toUpperCase().includes("MNBE")) {
    newType = "Mini Barn";
  } else if (itemName.toUpperCase().includes("MNSB")) {
    newType = "Playhouse";
  } else if (itemName.toUpperCase().includes("PDM1")) {
    newType = "Wood A-Frame";
  } else if (itemName.toUpperCase().includes("PLBG")) {
    newType = "Playhouse";
  } else if (itemName.toUpperCase().includes("PLCC")) {
    newType = "Playhouse";
  } else if (itemName.toUpperCase().includes("PLTE")) {
    newType = "Metal Economy";
  } else if (itemName.toUpperCase().includes("PLTG")) {
    newType = "Golf Cart Ready";
  } else if (itemName.toUpperCase().includes("PLTS")) {
    newType = "Premium Metal";
  } else if (itemName.toUpperCase().includes("PLTW")) {
    newType = "Metal Sided Wood Framed";
  } else if (itemName.toUpperCase().includes("RITZ")) {
    newType = "The Ritz";
  } else if (itemName.toUpperCase().includes("OSCR")) {
    newType = "The Oscar";
  } else if (
    itemName.toUpperCase().includes("BARN") &&
    floorPlanNumber.toUpperCase().includes("000")
  ) {
    newType = "Barn";
  } else if (
    itemName.toUpperCase().includes("BARN") &&
    (floorPlanNumber.toUpperCase().includes("001") ||
      floorPlanNumber.startsWith("8"))
  ) {
    newType = "Lofted Barn";
  } else if (
    itemName.toUpperCase().includes("UTLY") &&
    floorPlanNumber.includes("000")
  ) {
    newType = "Utility";
  } else if (
    itemName.toUpperCase().includes("UTLY") &&
    floorPlanNumber.includes("001")
  ) {
    newType = "Lofted Utility";
  } else if (itemName.toUpperCase().includes("GRDN")) {
    newType = "Garden Shed";
  } else if (itemName.toUpperCase().includes("TOOL")) {
    newType = "Tool Shed";
  } else if (itemName.toUpperCase().includes("HNDY")) {
    newType = "Lofted Barn Handyman";
  } else if (itemName.toUpperCase().includes("BGAR")) {
    newType = "Lofted Barn Garage";
  } else if (itemName.toUpperCase().includes("UGAR")) {
    newType = "Utility Garage";
  } else if (itemName.toUpperCase().includes("SLIM")) {
    newType = "Slim Shed";
  } else if (
    itemName.toUpperCase().includes("COOP") ||
    itemName.toUpperCase().includes("MBYC")
  ) {
    newType = "Coop";
  } else if (
    itemName.includes("CNDO") ||
    itemName.includes("DOG") ||
    itemName.includes("CAT")
  ) {
    newType = "Pet";
  } else if (itemName.includes("PLANTER")) {
    newType = "Planter";
  } else if (itemName.includes("COOP")) {
    newType = "Chicken Coop";
  } else if (itemName.toUpperCase().includes("PLANTER")) {
    newType = "Planter";
  }

  console.log("Item Name: " + itemName);
  console.log("Type: " + newType);

  return newType;
};

import { useState } from "react";
import { Card, CardGroup, Spinner } from "react-bootstrap";
import AddItemModal from "../components/admin/modals/AddItemModal";
import ListItemModal from "../components/admin/modals/ListItemModal";
import AddLotModal from "../components/admin/modals/AddLotModal";
import ListLotModal from "../components/admin/modals/ListLotModal";
import { auth } from "../firebase";
import ListUsersModal from "../components/admin/modals/ListUsersModal";
import UserDataService from "../services/user.services";
import { useEffect } from "react";
import AddStateModal from "../components/admin/modals/AddStateModal";
import ListStateModal from "../components/admin/modals/ListStateModal";

export default function Admin() {
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const isAdmin = async () => {
      setAdmin(await UserDataService.isAdmin(auth.currentUser.email));
    };
    isAdmin();
  }, []);

  if (!admin) {
    return (
      <>
        {loading ? (
          <div className="text-center" style={{ marginTop: "100px" }}>
            <Spinner animation="border" role="status" variant="warning">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="text-center" style={{ marginTop: "100px" }}>
            <h1>You are not an admin. </h1>
            <a href="/">Click here to go back to the dashboard.</a>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      {/* <CardGroup> */}
      {/* <Card className="text-center shadow-sm" style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>Add an item</Card.Title>
            <Card.Text>Click here to add an item to the dashboard.</Card.Text>
            <AddItemModal />
          </Card.Body>
        </Card>

        <Card className="text-center shadow-sm" style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title>View all items</Card.Title>
            <Card.Text>Click here to list all items.</Card.Text>
            <ListItemModal />
          </Card.Body>
        </Card>
      </CardGroup> */}

      {/* <CardGroup>
        <Card
          className="text-center shadow-sm"
          style={{ marginTop: "10px", width: "18rem" }}
        >
          <Card.Body>
            <Card.Title>Add a lot</Card.Title>
            <Card.Text>Click here to add a lot.</Card.Text>
            <AddLotModal />
          </Card.Body>
        </Card>

        <Card
          className="text-center shadow-sm"
          style={{ marginTop: "10px", width: "18rem" }}
        >
          <Card.Body>
            <Card.Title>View all lots</Card.Title>
            <Card.Text>Click here to list all lots.</Card.Text>
            <ListLotModal admin={true} modal={true} />
          </Card.Body>
        </Card>
      </CardGroup> */}
      {/* <CardGroup>
        <Card
          className="text-center shadow-sm"
          style={{ marginTop: "10px", width: "18rem" }}
        >
          <Card.Body>
            <Card.Title>Add a state</Card.Title>
            <Card.Text>Click here to add a state.</Card.Text>
            <AddStateModal />
          </Card.Body>
        </Card>
        <Card
          className="text-center shadow-sm"
          style={{ marginTop: "10px", width: "18rem" }}
        >
          <Card.Body>
            <Card.Title>List all states</Card.Title>
            <Card.Text>Click here to list all states.</Card.Text>
            <ListStateModal />
          </Card.Body>
        </Card>
      </CardGroup> */}
      <CardGroup>
        <Card className="text-center shadow-sm">
          <Card.Body>
            <Card.Title>View all users</Card.Title>
            <Card.Text>Click here to list all users.</Card.Text>
            <ListUsersModal admin={true} modal={true} />
          </Card.Body>
        </Card>
      </CardGroup>
    </>
  );
}

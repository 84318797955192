import axios from "axios";

const endpoint = "https://dev-2.leonardusa.com/api/internal/simplesigns/lots";

const api = axios.create({
  baseURL: endpoint,
  headers: {
    "Content-Type": "application/json",
  },
  auth: {
    username: "username",
    password: "password",
  },
});

const flowCodeApi = axios.create({
  baseURL: "https://gateway.flowcode.com/v3/",
  headers: {
    "Content-Type": "application/json",
    apikey: "ADpDMrnzMi1zMOmDTGeVPMS08vHTJ6s5",
  },
});

class LotDataService {
  addLot = async (newLot) => {
    return await api
      .post("/", newLot)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  updateLot = async (id, updatedItem) => {
    return await api
      .patch(`/${id}`, updatedItem)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  deleteLot = async (id) => {
    return await api
      .delete(`/${id}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  getAllLots = async () => {
    return await api
      .get("/")
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  getLot = async (id) => {
    return await api
      .get(`/${id}`)
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  generateSorryWeMissedYouQRCodeURL = async (brand, lotNumber, regionId) => {
    let domain =
      brand === "Cook"
        ? "me8qh51umco.typeform.com/to/jtfqfVOY"
        : "me8qh51umco.typeform.com/to/lIOiFX7E";
    let code = {
      name: `${lotNumber} Sorry We Missed You QR Code`,
      url: encodeURIComponent(
        `https://${domain}?store=${lotNumber}&region_id=${regionId}`
      ),
      data_pattern_shape: "square",
    };

    return flowCodeApi
      .get(
        `/codes/generator/static?url=${code.url}&name=${code.name}&data_pattern_shape=${code.data_pattern_shape}`
      )
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };

  generateQRCodeURL = async (
    brand,
    lotNumber,
    regionId,
    sku,
    price,
    category,
    subcategory
  ) => {
    let domain = brand === "Cook" ? "cookstuff.com" : "leonardusa.com";
    let cookStoresWithTrailers = ["888", "890", "893", "887", "881"];
    if (cookStoresWithTrailers.includes(lotNumber)) {
      domain = "leonardusa.com";
    }

    let code = {
      name: `${lotNumber} QR Code`,
      url: encodeURIComponent(
        `https://${domain}/sorry-we-missed-you?store=${lotNumber}&region_id=${regionId}${
          sku && `&sku=${sku}`
        }${price && `&price=${price}`}${category && `&category=${category}`}${
          subcategory && `&subcategory=${subcategory}`
        }`
      ),
      data_pattern_shape: "square",
    };
    // sku, store, region_id

    return flowCodeApi
      .get(
        `/codes/generator/static?url=${code.url}&name=${code.name}&data_pattern_shape=${code.data_pattern_shape}`
      )
      .then(({ data }) => data)
      .catch((err) => console.log(err));
  };
}

export default new LotDataService();

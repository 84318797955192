import { Container } from "react-bootstrap";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import Dashboard from "./pages/Dashboard";
import UpdateProfile from "./pages/auth/UpdateProfile";
import Navigation from "./components/Navigation";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/Signup";
import ForgotPassword from "./pages/auth/ForgotPassword";
import Admin from "./pages/Admin";
import ErrorPage from "./components/ErrorPage";
import OtherSigns from "./pages/OtherSigns";
import { QueryClient, QueryClientProvider } from "react-query";

// This function includes the authentication, navbar and routing for the application
// authentication - firebase
// navbar - react-bootstrap
// routing - react-router-dom
const queryClient = new QueryClient();

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Container className="d-flex align-items-center justify-content-center">
          <Router>
            <Navigation />
            <Routes>
              <Route
                path="*"
                element={
                  <PrivateRoute location="/">
                    <Route exact path="/" element={<Dashboard />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/update-profile/*"
                element={
                  <PrivateRoute location="/">
                    <Route exact path="/" element={<UpdateProfile />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/admin/*"
                element={
                  <PrivateRoute location="/">
                    <Route exact path="/" element={<Admin />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/signup/*"
                element={
                  <PublicRoute location="/">
                    <Route exact path="/" element={<Signup />} />
                  </PublicRoute>
                }
              />
              <Route
                path="/login/*"
                element={
                  <PublicRoute location="/">
                    <Route exact path="/" element={<Login />} />
                  </PublicRoute>
                }
              />
              <Route
                path="/forgot-password/*"
                element={
                  <PublicRoute location="/">
                    <Route exact path="/" element={<ForgotPassword />} />
                  </PublicRoute>
                }
              />
              <Route
                path="/other-signs/*"
                element={
                  <PrivateRoute location="/">
                    <Route exact path="/" element={<OtherSigns />} />
                  </PrivateRoute>
                }
              />
              <Route
                path="/*"
                element={
                  <PublicRoute location="/*">
                    <Route path="/*" element={<ErrorPage />} />
                  </PublicRoute>
                }
              ></Route>
            </Routes>
          </Router>
        </Container>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;

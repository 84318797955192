import { Navbar, Nav, Container } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import UserDataService from "../services/user.services";
import { useEffect, useState } from "react";

export default function Navigation() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    const isAdmin = async () => {
      let adminStatus = await UserDataService.isAdmin(auth.currentUser.email);
      setAdmin(adminStatus);
    };
    isAdmin();
  }, []);

  async function handleLogout() {
    try {
      await logout();
      navigate("/login");
    } catch {
      navigator("/");
    }
  }

  return auth.currentUser ? (
    <Navbar className="shadow-sm navbar-bg fixed-top" expand="lg" fixed="top">
      <Container style={{ fontSize: "1.10rem" }}>
        <Navbar.Brand href="/">
          <img
            alt="Leonard logo"
            src="https://leofilestorage.blob.core.windows.net/public-container/Images/leonard-truck-outfitters.png"
            height="auto"
            width="110px"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-start">
          <Nav className="me-auto">
            <Nav.Link href="/">Dashboard</Nav.Link>
            {/* <Nav.Link href="/other-signs">Other Signs</Nav.Link> */}
            <Nav.Link href="/other-signs">Other Signs</Nav.Link>
            {admin && <Nav.Link href="/admin">Admin</Nav.Link>}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Nav className="ms-auto">
            {/* <Nav.Link href="/update-profile">Profile</Nav.Link> */}
            <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  ) : null;
}

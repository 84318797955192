import { useState } from "react";
import {
  Table,
  Button,
  Spinner,
  InputGroup,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import ItemSelectModal from "./ItemSelectModal";
import Paginator from "./Paginator";
import ItemDetailsModal from "./ItemDetailsModal";
import ExportItemsButton from "../components/admin/buttons/ExportItemsButton.js";
import DeleteItemsButton from "./admin/buttons/DeleteItemsButton";
import Select from "react-select";
import { useQuery } from "react-query";
import {
  getBrand,
  getSize,
  calculateMonthlyPayment,
  getMonthlyPayment,
} from "../util/items";

const ItemList = ({ admin, modal, buildingsOnly }) => {
  const { isLoading, error, data } = useQuery("getItems", async () => {
    const response = await fetch(
      "https://dev-2.leonardusa.com/api/cp/items/search?category=BUILDINGS&status=A&obsolete=N&size=100000",
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": "abc12345",
        },
      }
    );

    const trailers = await fetch(
      "https://dev-2.leonardusa.com/api/cp/items/search?category=TRAILER&status=A&obsolete=N&size=100000",
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": "abc12345",
        },
      }
    );
    const buildingJson = await response.json();
    const trailerJson = await trailers.json();

    const items = buildingJson.content.concat(trailerJson.content);

    return items;
  });

  const {
    isLoading: lotsLoading,
    error: lotsError,
    data: lotsData,
  } = useQuery("getLots", async () => {
    const response = await fetch(
      "https://dev-2.leonardusa.com/api/cp/stores/search?active=Y&size=10000",
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": "abc12345",
        },
      }
    );
    return await response.json();
  });

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15);
  const [pagination, setPagination] = useState(true);
  const [lotNumber, setLotNumber] = useState();
  const [lotState, setLotState] = useState();

  const [searchQuery, setSearchQuery] = useState("");
  const [category, setCategory] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const [brand, setBrand] = useState("");

  if (isLoading || lotsLoading) return <p>Loading...</p>;
  if (error || lotsError) return <p>Error: {error.message}</p>;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredItems = data
    .filter((item) => !item.subcategory.includes("TRAILER AC"))
    .filter(
      (item) =>
        (!searchQuery ||
          item.itemNo.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (!category || item.category === category || "all" === category) &&
        (!subcategory ||
          item.subcategory === subcategory ||
          "all" === subcategory) &&
        (!brand || getBrand(item.itemNo) === brand)
    );

  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div
        className="list"
        style={modal && { width: "90%", left: "5%", marginTop: "0" }}
      >
        <div>
          <Row>
            <Col xs={modal ? 3 : 5}>
              <InputGroup
                className="mb-3"
                size="sm"
                style={modal ? {} : { marginLeft: "0px" }}
              >
                <InputGroup.Text id="basic-addon1">
                  <BsSearch />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search item number..."
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setCurrentPage(1);
                  }}
                  style={{ minHeight: "38px" }}
                />
              </InputGroup>
            </Col>
            <Col xs={modal ? "auto" : 1} style={{ width: "150px" }}>
              <Form.Group className="mb-4" id="lot-filter">
                <Select
                  noOptionsMessage={() => "No Lots Found"}
                  placeholder="Lot"
                  onChange={(e) => {
                    setLotNumber(e.value.storeNo);
                    setLotState(e.value.state);
                  }}
                  options={lotsData?.content?.map((lot, index) => ({
                    key: lot.storeNo,
                    value: { storeNo: lot.storeNo, state: lot.state },
                    label: lot.storeNo,
                  }))}
                />
              </Form.Group>
            </Col>

            <Col xs={modal ? "auto" : 2} style={{ width: "180px" }}>
              <Form.Group className="mb-4" id="type-filter">
                <Select
                  placeholder="Category"
                  onChange={(e) => setCategory(e.value)}
                  options={[
                    { key: "all", value: "", label: "All" },
                    ...[...new Set(data.map((item) => item.category))].map(
                      (category) => ({
                        key: category,
                        value: category,
                        label: category,
                      })
                    ),
                  ]}
                />
              </Form.Group>
            </Col>
            <Col xs={modal ? "auto" : 1} style={{ width: "200px" }}>
              <Form.Group className="mb-4" id="company-filter">
                <Select
                  placeholder="Subcategory"
                  options={[
                    { key: "all", value: "", label: "All" },
                    ...[
                      ...new Set(filteredItems.map((item) => item.subcategory)),
                    ].map((subcategory) => ({
                      key: subcategory,
                      value: subcategory,
                      label: subcategory,
                    })),
                  ]}
                  onChange={(e) => setSubcategory(e.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={modal ? "auto" : 2} style={{ width: "150px" }}>
              <Form.Group className="mb-4" id="company-filter">
                <Select
                  placeholder="Brand"
                  options={[
                    { key: "all", value: "", label: "All" },
                    { key: "leonard", value: "Leonard", label: "Leonard" },
                    { key: "cook", value: "Cook", label: "Cook" },
                  ]}
                  onChange={(e) => setBrand(e.value)}
                />
              </Form.Group>
            </Col>
            {admin && (
              <>
                <Row style={{ marginBottom: "15px" }}>
                  <Col xs="auto">
                    <ExportItemsButton data={data} />
                    <DeleteItemsButton />
                    <Button
                      size="sm"
                      variant="warning"
                      href={require("../files/SimpleSignTemplate.xlsx")}
                      download="SimpleSignTemplate"
                      style={{ marginLeft: "5px" }}
                    >
                      Download Template
                    </Button>
                  </Col>
                  <Col xs={modal ? "auto" : 1}>
                    <p>Total Items: {data.length}</p>
                  </Col>
                </Row>
              </>
            )}
          </Row>
        </div>
        {(loading || lotsLoading || isLoading) && (
          <Spinner animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <Table
          className="shadow-sm"
          striped
          hover
          bordered
          responsive="lg"
          size={"lg"}
        >
          <thead>
            <tr>
              <th>Item</th>
              <th>Description</th>
              <th>Size</th>
              <th>Category</th>
              <th>Subcategory</th>
              <th>Brand</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item) => {
              return (
                <tr key={item.itemNo}>
                  <td>{item.itemNo}</td>
                  <td>{item.description}</td>
                  <td>{getSize(item.itemNo)}</td>
                  <td>{item.category}</td>
                  <td>{item.subcategory}</td>
                  <td>{getBrand(item.itemNo)}</td>
                  <td>
                    <ItemSelectModal
                      inModal={modal}
                      item={item}
                      zoneNumber={1}
                      lot={lotNumber}
                      state={lotState}
                      company={getBrand(item.itemNo)}
                    />
                    <ItemDetailsModal item={item} admin={admin} />
                  </td>

                  {/* <td>
                    {!BuildingDataService.getBuildingTypeByItem(doc)
                      ? ""
                      : BuildingDataService.getBuildingTypeByItem(doc).type}
                  </td>
                  <td>{doc.company !== "Cook" ? "Leonard" : "Cook"}</td>
                  {admin && (
                    <td>{doc.dateAdded.replace(",", "").split(" ")[0]}</td>
                  )}
                  <td>
                    <ItemSelectModal
                      inModal={modal}
                      item={doc}
                      zoneNumber={zoneNumber}
                      lot={getLotByNumber(lotNumber)}
                      company={company}
                      buildingType={
                        !BuildingDataService.getBuildingTypeByItem(doc)
                          ? ""
                          : BuildingDataService.getBuildingTypeByItem(doc).type
                      }
                    />
                    <ItemDetailsModal item={doc} admin={admin} />
                    {admin && (
                      <>
                        <Button
                          variant="danger"
                          size="sm"
                          style={{ marginLeft: "5px" }}
                          onClick={(e) => deleteHandler(doc.name)}
                        >
                          Delete
                        </Button>
                      </>
                    )}
                  </td> 
                  */}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div>
          <Paginator
            visible={pagination}
            itemsPerPage={itemsPerPage}
            totalItems={data.length}
            paginate={paginate}
            currentPage={currentPage}
            isDataItems={true}
          />
        </div>
      </div>
    </>
  );
};

export default ItemList;

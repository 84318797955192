import { useState } from "react";
import { Button, Card, Alert, Spinner } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import firebase from "firebase/compat/app";
import { BsMicrosoft } from "react-icons/bs";
import UserDataService from "../../services/user.services.js";

export default function Login() {
  const { setDefaultUserData } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleMicrosoftLogin = () => {
    try {
      setError("");
      setLoading(true);
      const provider = new firebase.auth.OAuthProvider("microsoft.com");
      provider.setCustomParameters({
        tenant: "b9164165-f15e-487c-a26d-0ad1bec60cb8",
      });
      auth
        .signInWithPopup(provider)
        .then((data) => {
          try {
            UserDataService.addUser(
              JSON.stringify({
                email: data.user.email,
              })
            );
          } catch (e) {
            console.log(e.message);
          }
        })
        .then(() => {
          setError("");
          setLoading(true);
          navigate("/");
        })
        .catch((error) => {
          console.log(error.message);
        });
    } catch (err) {
      setError(err.message);
    }

    setLoading(false);
  };

  return (
    <>
      <div
        style={{
          textAlign: "center",
          marginBottom: "0px",
          marginTop: "55px",
        }}
      >
        <img
          alt="Leonard logo"
          src="https://leofilestorage.blob.core.windows.net/public-container/Images/new/leonard-regular-transparent.png"
          height="50%"
          width="50%"
        />
      </div>
      <Card border="warning" style={{ marginTop: "35px" }}>
        {loading && (
          <Spinner animation="border" role="status" variant="warning">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
        <Card.Body>
          <h2 className="text-center mb-4">Log in</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <p className="text-center" style={{ marginBottom: "0px" }}>
            Please use the button below to sign into
          </p>
          <p className="text-center" style={{ marginTop: "0px" }}>
            Leonard Simple Sign.
          </p>
          <Button
            style={{ marginTop: "10px", width: "100%" }}
            onClick={handleMicrosoftLogin}
            variant="outline-primary"
          >
            <BsMicrosoft style={{ marginRight: "5px", marginBottom: "3px" }} />
            Sign in using Microsoft
          </Button>
        </Card.Body>
      </Card>
    </>
  );
}

import { useState, useRef } from "react";
import {
  Form,
  Modal,
  Button,
  Col,
  Row,
  InputGroup,
  Overlay,
} from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import BuildingSignForm from "../forms/BuildingSignForm";
import SimpleSignForm from "../forms/SimpleSignForm";
import { calculateMonthlyPayment, getBrand, getSize } from "../util/items";

function ItemSelectModal({ inModal, item, lot, state, zoneNumber, company }) {
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (!item || !lot || !zoneNumber) {
      setError("Please make sure you have a lot selected.");
      if (inModal) {
        alert("Please make sure you have a lot selected.");
        return;
      }
      setTimeout(() => {
        setError("");
      }, "1500");
      return;
    }

    const loadPrice = () => {
      setCashPrice(item.listPrice);

      if (item.category.includes("BUILDINGS")) {
        setType(item.category);
      } else if (item.category.includes("TRAILER")) {
        setType(item.category);
      } else {
        setType("");
      }
    };

    loadPrice();
    setFormType("everything");
    setShow(true);
  };

  const [formType, setFormType] = useState("everything");

  const [cashPrice, setCashPrice] = useState(item.listPrice);
  const [monthly, setMonthly] = useState(
    calculateMonthlyPayment(
      cashPrice,
      60,
      item.category.includes("TRAILER")
    ).toFixed(0)
  );
  const [type, setType] = useState("");

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function getForm(ref, category, formType, subcategory) {
    if (category.includes("BUILDINGS")) {
      const size =
        item.itemNo.startsWith("PLANTER") && item.itemNo.endsWith("RB")
          ? "Raised Bed"
          : item.itemNo.startsWith("PLANTER") && item.itemNo.endsWith("B")
          ? "Bucket"
          : getSize(item.itemNo);
      return (
        <BuildingSignForm
          ref={ref}
          lot={lot}
          size={size}
          company={getBrand(item.itemNo)}
          state={state}
          itemName={item.itemNo}
          type={item.category}
          msrp={cashPrice}
          monthly={monthly}
          cardPrice={cashPrice}
          cashPrice={cashPrice}
          formType={formType}
          buildingType={subcategory}
        />
      );
    }

    if (!category.includes("BUILDINGS")) {
      return (
        <div style={{ display: "none" }}>
          <SimpleSignForm
            ref={ref}
            lot={lot}
            size={getSize(item.itemNo)}
            gvwr={item.gvwr ?? ""}
            itemName={item.itemNo}
            type={item.subcategory}
            category={item.category}
            msrp={cashPrice}
            // monthly={calculateMonthlyPayment(item.listPrice, 48, true)
            //   .toFixed(0)
            //   .toString()}
            monthly={monthly}
            cardPrice={cashPrice}
            cashPrice={cashPrice}
          />
        </div>
      );
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handlePrint();
  };

  return (
    <>
      <Button variant="success" ref={target} size="sm" onClick={handleShow}>
        Select
      </Button>
      {error && (
        <Overlay target={target.current} show={error} placement="right">
          {({ placement, arrowProps, show: _show, popper, ...props }) => (
            <div
              {...props}
              style={{
                position: "absolute",
                backgroundColor: "#ee5253",
                padding: "2px 5px",
                marginLeft: "15px",
                marginBottom: "10px",
                color: "white",
                borderRadius: 2,
                ...props.style,
              }}
            >
              You must select a lot
            </div>
          )}
        </Overlay>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Item Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="confirm-item-input">
              <Row>
                <h5>Lot #: {lot}</h5>
              </Row>
            </Form.Group>
            {type === "BUILDINGS" && (
              <>
                <Form.Label>Form Type</Form.Label>
                <Form.Select
                  onChange={(e) => setFormType(e.currentTarget.value)}
                  style={{
                    width: "40%",
                    marginBottom: "20px",
                    marginTop: "0px",
                  }}
                >
                  <option key="everything" value="everything">
                    Everything
                  </option>
                  <option key="priceonly" value="priceonly">
                    Price Only
                  </option>
                  <option key="sizeonly" value="sizeonly">
                    Size Only
                  </option>
                </Form.Select>
              </>
            )}

            {item.company !== "Cook" ? (
              <>
                <Row>
                  <Col>
                    <Form.Label>Price</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Cash Price"
                        defaultValue={cashPrice}
                        required
                        onChange={(e) => setCashPrice(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Label>Monthly</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Monthly"
                        defaultValue={monthly}
                        required
                        onChange={(e) => setMonthly(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col>
                    <Form.Label>List Price</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="List Price"
                        defaultValue={cashPrice}
                        required
                        onChange={(e) => setCashPrice(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <Form.Label>Monthly</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Text>$</InputGroup.Text>
                      <Form.Control
                        type="text"
                        placeholder="Monthly"
                        defaultValue={monthly}
                        required
                        onChange={(e) => setMonthly(e.target.value)}
                        aria-label="Amount (to the nearest dollar)"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              </>
            )}

            <div style={{ display: "none" }}>
              {getForm(componentRef, item.category, formType, item.subcategory)}
            </div>
            <Button style={{ marginRight: "5px" }} onClick={handleSubmit}>
              Print
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ItemSelectModal;

import React, { useEffect, useState } from "react";
import PageItem from "../components/PageItem";
import { Container, Col, Row } from "react-bootstrap";
import Select from "react-select";
import LotDataService from "../services/lot.services.js";
import { useQuery } from "react-query";
import { getBrand } from "../util/store";

export default function OtherSigns() {
  const { isLoading, error, data } = useQuery("getLots", async () => {
    const response = await fetch(
      "https://dev-2.leonardusa.com/api/cp/stores/search?active=Y&size=10000",
      {
        headers: {
          "Content-Type": "application/json",
          "X-API-Key": "abc12345",
        },
      }
    );
    return await response.json();
  });

  const [lotNumber, setLotNumber] = useState();
  const [lotState, setLotState] = useState();
  const [lotBrand, setLotBrand] = useState();

  if (isLoading) return <p>Loading...</p>;

  return (
    <>
      <h1 style={{ marginTop: "100px", textAlign: "center" }}>Select a Sign</h1>
      <Container>
        <p style={{ marginBottom: 0, paddingBottom: 0 }}>Select a lot</p>
        <div style={{ width: "15vw" }}>
          <Select
            noOptionsMessage={() => "No Lots Found"}
            placeholder="Lot"
            onChange={(e) => {
              setLotNumber(e.value.storeNo);
              setLotState(e.value.state);
              setLotBrand(e.value.brand);
            }}
            options={data?.content?.map((lot, index) => ({
              key: lot.storeNo,
              value: {
                storeNo: lot.storeNo,
                state: lot.state,
                brand: getBrand(lot),
              },
              label: lot.storeNo,
            }))}
          />
        </div>

        <br />

        <Row>
          <Col xs={12} md={4}>
            <PageItem
              title="Leonard - Sorry We Missed You"
              description="Place this on your door if you have to close temporarily."
              src={require("../images/sorry-we-missed-you-leonard.png")}
              lot={lotNumber}
              lotState={lotState}
              brand={lotBrand}
              type="sorry-we-missed-you"
            />
          </Col>
          <Col xs={12} md={4}>
            <PageItem
              title="Cook - Sorry We Missed You"
              description="Place this on your door if you have to close temporarily."
              src={require("../images/sorry-we-missed-you-cook.png")}
              lot={lotNumber}
              lotState={lotState}
              brand={lotBrand}
              type="sorry-we-missed-you"
            />
          </Col>
          {/* <Col xs={12} md={4}>
            <PageItem
              title="Sold Sign"
              description="Place this on a product that has been sold."
              src={require("../images/old-building-simple-sign.jpg")}
            />
          </Col>
          <Col xs={12} md={4}>
            <PageItem
              title="Repo Sign"
              description="Place this on a repossessed product."
              src={require("../images/old-building-simple-sign.jpg")}
            />
          </Col> */}
        </Row>
      </Container>
    </>
  );
}

import React, { useState } from "react";
import { Button, Modal, Form, Row, Col, Alert } from "react-bootstrap";
import ItemDataService from "../services/item.services";
import { calculateMonthlyPayment, getBrand, getSize } from "../util/items";

function ItemDetailsModal({ item, admin }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <>
      <Button
        variant="warning"
        size="sm"
        style={{ marginLeft: "5px" }}
        onClick={handleShow}
      >
        Details
      </Button>
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Item: {item.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h4 className="text-center">Information</h4>
            {admin && <p>Added by: {item.addedBy}</p>}
            <Row>
              <Col>
                <Form.Group className="mb-4" id="item-name">
                  <Form.Label>Item Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item name"
                    value={item.itemNo}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-4" id="item-type">
                  <Form.Label>Category, Subcategory</Form.Label>
                  <Form.Control
                    type="text"
                    value={`${item.category}, ${item.subcategory}`}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Group className="mb-4">
                  <Form.Label>Item Size</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Item size"
                    disabled={true}
                    value={getSize(item.itemNo)}
                  />
                </Form.Group>
              </Col>
              {/* {item.category !== "BUILDINGS" && (
                <Col>
                  <Form.Group className="mb-4">
                    <Form.Label>Item GVWR</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Gross vehicle weight rating"
                      value={gvwr}
                      disabled={!admin}
                      required
                      onChange={(e) => setGvwr(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              )} */}
              {item.category === "BUILDINGS" && (
                <Col xs={6}>
                  <Form.Group className="mb-4">
                    <Form.Label>Brand</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Brand"
                      disabled={true}
                      value={getBrand(item.itemNo)}
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <h4 className="text-center">Pricing</h4>
            {/* Cash Pricing */}
            <Row>
              <Col xs={2}>
                <p style={{ marginTop: "5px" }}>List Price</p>
              </Col>
              <Col xs="auto">
                <Form.Group className="mb-4" id="zone-cash">
                  <Form.Control
                    type="text"
                    value={item.listPrice}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={2}>
                <p style={{ marginTop: "5px" }}>Monthly</p>
              </Col>
              <Col xs="auto">
                <Form.Group className="mb-4" id="zone-monthly">
                  <Form.Control
                    type="text"
                    placeholder="Monthly"
                    value={calculateMonthlyPayment(
                      item.listPrice,
                      60,
                      item.category.includes("TRAILER")
                    )}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              variant="secondary"
              onClick={handleClose}
              style={{ marginLeft: "7px" }}
            >
              Close
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ItemDetailsModal;
